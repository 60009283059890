<template>
  <div class="home">
    <el-card class="login-form">
      <h1 class="site-name">basenbyte.com</h1>
      <h2 class="sub-title">官网后台管理系统</h2>
      <el-form :model="form" :rules="rules" ref="form" class="form" label-position="top">
        <el-form-item prop="username">
          <el-input v-model="form.username" placeholder="请输入管理员账号" />
        </el-form-item>
        <el-form-item prop="password">
          <el-input
            v-model="form.password"
            show-password
            placeholder="请输入管理员密码"
            @keyup.enter.native="loginHandle" />
        </el-form-item>
        <el-form-item>
          <p class="form-item-tip">忘记密码请联系管理员</p>
          <el-button type="primary" @click="loginHandle" style="width: 100%;" :loading="submitting">登 录</el-button>
        </el-form-item>
      </el-form>
    </el-card>
  </div>
</template>

<style lang="scss">
  .home {
    position: relative;
    padding-top: 100px;
    height: 100%;
    box-sizing: border-box;
    background: #fff;
    background-size: cover;
    .site-name, .sub-title {
      text-align: center;
    }
    .site-name {
      font-size: 42px;
    }
    .sub-title {
      font-size: 16px;
      font-weight: normal;
      color: #666;
      margin-bottom: 2em;
    }
  }
  .home .login-form {
    width: 500px;
    margin: 50px auto 0;
    background: rgba(255, 255, 255, .6);
  }
</style>
<script>
import { SITE_NAME } from '@/config/app'
import { setToken } from '@/libs/storage'
import { sign } from '@/request/modules/user'

export default {
  data () {
    return {
      form: {
        username: '',
        password: ''
      },
      rules: {
        username: [
          { required: true, message: '请输入管理员账号', trigger: 'blur' }
        ],
        password: [
          { required: true, message: '请输入管理员密码', trigger: 'blur' }
        ]
      },
      submitting: false,
      start: new Date().getDay(),
      end: 6,
      SITE_NAME
    }
  },
  methods: {
    loginHandle () {
      this.$refs.form.validate(valid => {
        if (valid) {
          let form = this.form
          this.submitting = true
          sign(form).then(() => {
            this.submitting = false
            setToken(new Date().getTime().toString())
            this.$router.push('/dashboard')
          }).catch(() => {
            this.submitting = false
          })
        }
      })
    }
  }
}
</script>
